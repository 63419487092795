<template>
  <div
    :class="['organism gallery-grid', 'container-' + organism.containerWidth]"
    v-if="gallery?.length"
    :style="'background-color:' + organism.backgroundColor"
  >
    <ul
      class="px-4 mx-auto mb-16 grid grid-cols-3 md:grid-cols-4 gap-4 max-w-7xl"
    >
      <li
        v-for="(item, index) in gallery"
        :class="[
          index >= thumbnailCount ? 'hidden' : '',
          'index-' + index,
          '[&:nth-child(7n-6)]:col-span-2 [&:nth-child(7n-6)]:row-span-2 max-h-[200px] [&:nth-child(7n-6)]:max-h-[416px] bg-gray-600 relative',
        ]"
      >
        <button
          @click="modalImageSlider.openModal(index)"
          class="w-full h-full group"
        >
          <img
            class="object-cover w-full h-full group-hover:opacity-50 transition-opacity"
            :src="item.imageThumbnailSrc"
            :alt="item.alt"
            loading="lazy"
          />
          <div
            class="absolute inset-0 items-center justify-center hidden group-hover:flex"
          >
            <i class="block text-5xl text-white icon icon-zoom-in"></i>
          </div>
        </button>
      </li>
    </ul>
    <OrganismsModalImageSlider />
  </div>
</template>

<style>
.gallery-grid {
}
</style>

<script setup>
import { storeToRefs } from "pinia";
const modalImageSlider = useModalImageSlider();
const { sliderImages } = storeToRefs(modalImageSlider);
const props = defineProps({
  organism: Object,
});

const thumbnailCount = computed(() => {
  return props.organism.thumbnailCount ? props.organism.thumbnailCount : 10;
});

// console.log("props.organism", props.organism);
const gallery = computed(() => {
  const images = props?.organism?.gallery?.map((item) => {
    return {
      imageThumbnailSrc: getImageSrc(item, "large"),
      imageFullSrc: item?.sourceUrl,
      alt: item?.altText,
    };
  });
  sliderImages.value = images;
  return images;
});
// console.log("gallery", gallery);

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>

<template>
  <TransitionRoot appear :show="modalIsOpen" as="template">
    <Dialog as="div" @close="modalImageSlider.closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-center justify-center h-full px-8 text-center">
          <DialogOverlay
            class="fixed inset-0 flex items-center justify-center bg-gray-900 opacity-50"
          />

          <span class="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="transition-opacity duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              class="relative inline-block w-full max-w-5xl shadow-xl transition-all transform"
            >
              <Swiper
                :navigation="true"
                :loop="true"
                :auto-height="true"
                :pagination="true"
                :keyboard="true"
                :initial-slide="initialSlide"
                :modules="modules"
                class=""
              >
                <SwiperSlide
                  v-for="(image, index) in sliderImages"
                  :key="'slider-image-' + index"
                  class="select-none"
                >
                  <img
                    class="object-cover w-full h-full"
                    :src="filterWPContent(image.imageFullSrc)"
                    alt="image.alt"
                    loading="lazy"
                  />
                </SwiperSlide>
              </Swiper>
              <button
                type="button"
                class="absolute z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full -top-3 -right-3"
                @click="modalImageSlider.closeModal"
              >
                <i
                  class="block text-4xl text-bc-dark-blue relative top-[3px] icon icon-exit-circle"
                ></i>
              </button>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
const modalImageSlider = useModalImageSlider();
const { modalIsOpen, initialSlide, sliderImages } =
  storeToRefs(modalImageSlider);
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const modules = [Navigation, Pagination, Keyboard];
</script>
